import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BannerBlog from "../../components/blog/blogArticleBanner.component"
import BlogOtherDetails from "../../components/blog/blogOtherDetails.component"

const Services = () => {
  return (
    <div>
      <Layout>
        <Seo title="All of the above reasons are signs you need to see the dentist, but equally important is the impact that your oral health has on your overall well being. Cardiovascular disease, diabetes and alzheimers have all been linked to poor oral health. Don’t put it off another day, call us at Dinkha Dental to schedule your appointment. We look forward to being your partner in optimal oral and overall health!" />
        <BannerBlog title="Blog - Seven Signs You Should Not Ignore About Your Teeth" />
        <BlogOtherDetails />
      </Layout>
    </div>
  )
}

export default Services
