import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const BlogOtherDetails = () => {
  return (
    <>
      <div className="section sub-container">
        <div className="flex space-between">
          <div>
            <h3>Seven Signs You Should Not Ignore About Your Teeth</h3>
            <p style={{ maxWidth: "650px" }}>
              It’s been a while since your last dental appointment and you are
              wondering if it’s okay to put off a dental visit for a little
              longer. It is never a good idea to put off routine dental exams
              and professional cleanings because these appointments are critical
              for the early detection and intervention of dental concerns that
              left untreated can lead to disastrous consequences.
            </p>
          </div>
          <div style={{ width: "40%" }}>
            <StaticImage src="../../images/emergency.png" alt="emergency" />
          </div>
        </div>
        <div className="section">
          <p>
            We understand that sometimes life events and circumstances get in
            the way, and before you know it time has passed and you are thinking
            just how worried you should be about the lack of dental care you
            find yourself facing. Relax! We are here to help! A great first step
            is to make an appointment with Dinkha Dental ASAP. Our friendly and
            knowledgeable staff will welcome you to our state-of-the-art dental
            office, ensuring that you are comfortable, in a judgment-free zone,
            surrounded by a dental team invested in restoring and maintaining
            your dental health.
          </p>
          <p>
            We definitely recommend keeping routine dental appointments as the
            most important way to achieve and maintain optimal oral health, but
            here are some signs that immediate dental attention is vital.
          </p>
          <ol>
            <li>
              PAIN is the number one issue that prompts people to seek dental
              care outside of routine exams and cleanings. Pain is your mouth’s
              distress signal to get your attention so that you can address
              potentially serious dental problems. Toothaches can be caused by a
              cavity but they can also be indicative of an impacted tooth, an
              abscess or of gum disease, Prompt dental assessment to determine
              the cause and appropriate treatment is critical to minimize
              damage. Left untreated, tooth loss or worse may be the outcome.
            </li>
            <li>
              DRY MOUTH is a sign that your oral health is not what it should
              be, a healthy mouth is well lubricated by saliva. Having enough
              saliva is important not only because a dry mouth is uncomfortable,
              but also because saliva plays an important role in washing away
              food particles and neutralizing the acids that produce plaque and
              tartar. Your dentist can help identify the cause of your dry
              mouth, and provide ways to restore moisture and protect your oral
              health.{" "}
            </li>{" "}
            <li>
              LOOSE or SHIFTING adult teeth are always a cause of concern. Once
              adult teeth are present, they should remain securely in their
              place. If you notice gaps getting bigger, slight movement, or
              changes in the way your bite fits together, notify your dentist
              right away. Infection or bone loss could be the cause of unwanted
              movement and needs immediate intervention.{" "}
            </li>
            <li>
              BLEEDING GUMS in any amount after brushing or flossing is not
              normal and is often a sign of early periodontal disease, also
              known as gum disease. Treated in the early stage known as
              gingivitis, gum disease is reversible. Once gum disease advances
              to periodontitis it is no longer reversible and the goal of
              treatment shifts to slowing the progression, this is certainly a
              case for early treatment. Periodontal disease is a leading cause
              of tooth and bone loss, don’t let this happen to you! Make an
              appointment with the dentist at the first sign of bleeding gums.{" "}
            </li>
            BUMPS and SORES in the mouth such as canker sores will clear up on
            their own in less than two weeks. If you have a sore that does not
            clear up in this time frame, or begins to worsen instead of getting
            better, it’s time to see the dentist. Oral lesions can be caused by
            fungal infections such as thrush, and need to be treated with
            medication to be resolved. Some people are predisposed to these
            infections by preexisting issues like diabetes. The dentist will
            prescibe appropriate medication to treat the infection.
            <li>
              RECEDING GUMS is the term used when the gum tissue that surrounds
              a tooth wears away or pulls back from the tooth, exposing more of
              the surface and eventually the delicate root of the tooth. A small
              amount of receding gum can be attributed to aging but gum disease
              is often the culprit. It is important for the dentist to determine
              the cause of receding gums as soon as possible. Caught early,
              treatment can stop or even reverse receding gums. If not
              addressed, receding gums can cause tooth and bone loss.{" "}
            </li>{" "}
            <li>
              SENSITIVITY to HOT and COLD foods not only causes discomfort, it’s
              a sign something is not right with your oral health. Tooth decay
              is frequently the cause of this pain as it weakens and thins out
              the enamel on your tooth, exposing highly sensitive nerves.
              Consulting with your dentist ASAP will allow for necessary
              treatment to get you back to enjoying eating all your favorite
              foods and beverages pain free again.
            </li>
          </ol>
          <p>
            All of the above reasons are signs you need to see the dentist, but
            equally important is the impact that your oral health has on your
            overall well being. Cardiovascular disease, diabetes and alzheimers
            have all been linked to poor oral health. Don’t put it off another
            day, call us at Dinkha Dental to schedule your appointment. We look
            forward to being your partner in optimal oral and overall health!{" "}
          </p>
        </div>
      </div>
    </>
  )
}

export default BlogOtherDetails
